.bo-heading {
  align-items: center;
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-top: $heading-padding-top;
  padding-bottom: $heading-padding-bottom;
  
  @include reverse-breakpoint($medium) {
    flex-flow: wrap;
    min-height: 60px;
    height: unset;
    padding: 10px 0px;
    flex-shrink: 0;
  }
}

.bo-heading__title {
  display: flex;
  align-items: center;
  color: palette('grey', 'semiDark');
  font-family: font('source-light');
  font-size: 1.5em;
  letter-spacing: 0.5px;
  height: $heading-title-height;

  @include breakpoint($medium) {
    font-size: 1.6em;
  }
  img{
    cursor: pointer;
    padding-right: 10px;
  }
}

.bo-controls-heading{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: $heading-padding-top;
  padding-bottom: $heading-padding-bottom;

  &.heading--mobile{
    padding: 20px 0;
  }

  .bo-heading__left{
    padding-right: 10px;

    .bo-heading__controls {
      padding-top: $heading-padding-bottom;

      @include reverse-breakpoint($medium) {
        height: unset;
        min-height: unset;
        flex-flow: column;
      }  
    }

    .breadcrumb{
      font-weight: bold;
  
      .breadcrumb-separator{
        padding: 5px 10px 0 10px;
      }
  
      .breadcrumb-link{
        color: #50c8e8;
        cursor: pointer;
      }
  
      .breadcrumb-second-part{
        display: flex;
        align-items: center;
      }
      
      @include reverse-breakpoint($small){
        .breadcrumb-description{
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          max-width: 190px;
        }
      }
    }
  }

  .bo-heading__right{
    display: flex;
    padding-top: $heading-padding-top;
    
    &--end{
      @extend .bo-heading__right;
      align-items: end;
    }

    @include reverse-breakpoint($medium) {
      width: 100%;
    }

    .bo-heading__infos {
      width: 100%;
    }

    .bo-heading__slider{
      display: flex;
      flex-flow: wrap;
      width: 700px;

      @include reverse-breakpoint($medium) {
        width:100%;
      }

      .bo-heading__title{
        padding-bottom: 10px;
        font-size: 16px;
        font-family: 'source_sans_prolight';
      }
    }  
  }
}