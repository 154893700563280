.report-heading {
  display: flex;
  width: 100%;
  flex-flow: column;
}

.bo-heading__reporting{
  color: #40454c;
  font-family: source_sans_prolight;
  font-size: 1.5em;
  letter-spacing: 0.5px;
  padding: 20px 0px;
  align-items: center;
  display: flex;
  @include breakpoint($small) {
    height: 100px;
  }
}
.reporting__menu{
  display: flex;
  flex-flow: row;
  @include reverse-breakpoint($large) {
    flex-flow: column;
  }
}
.reporting__left-menu{
  display: flex;
  flex-flow: row;
  @include breakpoint($small) {
    float: left;
  } 
  @include reverse-breakpoint($small) {
    width: 100%;
    padding: 10px 0;
    flex-flow: column;
  }
}
.reporting__filters{
  display: flex;
  width: -webkit-fill-available;
  padding: 0 10px;

  @include reverse-breakpoint($large) {
    width: 100%;
    padding: 10px 0;
    flex-flow: column;
  }
}
.reporting__right-menu{
  display: flex;
  flex-flow: row;
  width: 100%;
  @include reverse-breakpoint($large) {
    display: flex;
    flex-flow: row;
    @include reverse-breakpoint($medium) {
      display: flex;
      flex-flow: row;
      margin-top: unset;
      @include reverse-breakpoint($small) {
        flex-flow: column;
      }
    }
  }
}
.reporting__buttons{
  @include breakpoint($small) {
    float: right;
    display: flex;
    flex-flow: row;
    .reporting__ruc{
      float:left;
      padding-right: 10px;
    }
    .reporting__print{
      float:right;
    }
  }
  @include reverse-breakpoint($large) {
    flex-flow: row;
    padding: 10px 0 10px 10px;
    @include reverse-breakpoint($medium) {
      width: unset;
      flex-flow: row;
    }
    @include reverse-breakpoint($small) {
      width: 100%;
      display: flex;
      flex-flow: row;
      justify-content: space-between;
      padding: 10px 0;
      .button{
        font-size: 0.8em;
      }
    }
  }
}
.reporting__period{
  @include reverse-breakpoint($small) {
    width: 100%;
  }
  width: 250px;
}

.reporting__target{
  @include reverse-breakpoint($small) {
    width: 100%;
    margin: 0;
    padding-top: 10px;
  }
  width: 250px;
  float:right;
  margin-left: 10px;
}

.float-r {
  float: right;
}

.float-l {
  float: left;
}


.inline-block {
  display: inline-block;
}

.picker-row {
  padding: 10px 0px;
  font-weight: normal;

  &.no-child__col {
    font-weight: 600;
  }
}
.picker-row:not(.selected__col):hover {
  background-color: #bfbfbf;
}
.picker-row:hover{
  cursor: pointer;
  .hover-show{
    display: block;
    visibility: visible;
  }
}


.head-grouping{
  display: flex;
  align-items: center;
  min-height: 50px;
  .head-grouping__title{
    cursor: pointer;
    display: flex;
    align-items: center;
    color: #50c8e8;
    font-size: 20px;
  }
}

.visualize__options{
  margin-bottom: 10px;
  .button{
    height: 37px;
    min-height: 37px;
  }
}
.title__back-icon{
  font-size: 40px;
  padding-right: 10px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  text-decoration: none;
  color: #40454c;
}